// Fonts
$font-stack: 'Outfit', sans-serif;;

// Font sizes.
$sizes: (
  xl: 18px,
  lg: 16px,
  base: 14px,
  small: 12px
);


// Button Box Shadow
$button-box-shadow: 0 8px 19px -7px rgba(0, 0, 0, 0.3);

:root {
  --color-white:          #ffffff;
  --color-black:          #000000;
  --color-black2:         #0A1223;
  --color-blackish:       #464967;
  --color-blackish2:      #202B40;
  --color-blackish3:      #1C2942;
  --color-gray:           #C6C6C6;
  --color-gray-dark:      #33343D;
  --color-gray-light:     #F7F7F7;
  --color-primary:        #3F5475;
  --color-secondary:      #E2857E;
  --color-pink:           #ECB2AE; 
  --color-navy:           #293856;
  --color-navy2:          #2A3756;
  --color-navy-light:     #586F94;
  --color-blue-light:     #88CFDE;
  --color-blue-lighter:   #A9DCE6;
  --color-green:          #8DCF9F;
  --color-green-light:    #AADCB9;
  --color-green-soft:     #9DDDAE;
  --color-greenish:       #A4DBB3;
  --color-blue-soft:      #818399;
  --color-transparent:    transparent;

  --body-font: #{$font-stack};

  // Font sizes
  @each $name, $size in $sizes {
    --font-size-#{$name}: #{$size}
  }
}
  

// Breakpoints map
$breakpoints: (
  'xs_up': (min-width: 576px), // Small devices (landscape phones, 576px and up)
  'sm_up': (min-width: 768px), // Medium devices (tablets, 768px and up)
  'md_up': (min-width: 992px), // Large devices (desktops, 992px and up)
  'lg_up': (min-width: 1200px), // Extra large devices (large desktops, 1200px and up)

  'xxs_down': (max-width: 450px),
  'xs_down': (max-width: 575.98px), // Extra small devices (portrait phones, less than 576px)
  'sm_down': (max-width: 767.98px), // Small devices (landscape phones, less than 768px)
  'md_down': (max-width: 991.98px), // Medium devices (tablets, less than 992px)
  'lg_down': (max-width: 1199.98px), // Large devices (desktops, less than 1200px)
  'xlg_down': (max-width: 1599.98px), // Large devices (desktops, less than 1600px)

  'xs_up-sm_down': (min-width: 576px) and (max-width: 767.98px),
  'sm_up-md_down': (min-width: 768px) and (max-width: 991.98px),
  'md_up-lg_down': (min-width: 768px) and (max-width: 991.98px),
  'sm-up-lg_down': (min-width: 768px) and (max-width: 1199.98px)
) !default;
