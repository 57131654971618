// Mixins
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover {
      @content;
    }
  }
}

// .example {
//   @include on-event($self:true or false) {
//      text-decoration: underline;
//   }
// }


// Responsive manager
// @param {String} $breakpoint - Breakpoint
// @requires $breakpoints
@mixin breakpoint($breakpoint) {
  $query: map-get($breakpoints, $breakpoint);

  @if not $query {
    @error 'No value found for #{$breakpoint}. Please make sure it is defined in $breakpoints map.';
  }

  @media #{if(type-of($query) == 'string', unquote($query), inspect($query))} {
    @content;
  }
}

// Transition Mixin
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// USAGE, Example
// .example {
//   @include transition(all 0.6s ease-in-out);
// }


// Positioning Mixin
@mixin position($position: static, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
// USAGE, Example
// .example {
//     @include position(absolute, 10px, 10px, 5px, 15px);
//   }

// Button Common Styles
@mixin button ($bg-color, $color) {
  background-color: color($bg-color);
  color: color($color);

  @include on-event($self: false) {
    box-shadow: $button-box-shadow;
    background-color: color($bg-color);
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

// Flexible
@mixin display($flex: flex, $align-items: inherit, $justify-content: inherit, $flex-direction: inherit) {
  display:  $flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
}
// USAGE, Example
// .example {
//    @include display(flex, center, center);
// }

//Font Size Responsive
@mixin fontsize-r() {
  font-size: min(max(14px, 2vw), 18px);
}

// Margin and Padding //
$sides: ("t": "top", "r": "right", "b": "bottom", "l": "left");
@each $prefix, $value in $sides {
  @for $i from 0 through 20 {
    .m#{$prefix} {
      &#{($i * 10)} {
        margin-#{$value}:#{($i * 10)}px;
      }
    }
    .p#{$prefix} {
      &#{($i * 10)} {
        padding-#{$value}:#{($i * 10)}px;
      }
    }
  }
}

// USAGE, Example //
//<div class="mt50 pt20 pb20">
//  content example
//</div>


// Placeholder //
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

// USAGE, Example //
//  input,
//  textarea {
//    @include input-placeholder {
//      color: var(--color-gray);
//    }
//  }

// Truncate //
@mixin truncate($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Triangle scss mixin //
@mixin triangle($position: 'left', $size: 8px, $color: $black) {
  width: 0;
  height: 0;
  content: "";

  @if $position == 'left' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
  @if $position == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }
  @if $position == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
  @if $position == 'bottom' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
}

// Keyframes  //
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

//  USAGE, Example //
//  @include keyframes(background) {
//    0% {
//      background: white;
//    }
//    50% {
//      background: lightblue;
//    }
//    100% {
//      background: royalblue;
//    }
//  }

// Background cover //
@mixin background-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

//  USAGE, Example //
// .example {
//    background-image: url("example.png");
//    @include background-cover;
// }

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// USAGE, Example //
//  .example {
//    @include box(200px);
//  }
//
//  .example2 {
//    @include box (100px, 300px);
//  }

@mixin retina($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* Serving 2x image on Retina display */
    background-image: url($image);
    background-size: $width $height;
  }
}

// USAGE, Example //
//  .logo {
//    background-image: url("img/example.png");
//    @include retina("img/logo@2x.png", 100px, 21px);
//  }


@mixin gradient($first-color, $last-color, $orientation) {
  background: $first-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $first-color, $last-color);
    background: linear-gradient(to bottom, $first-color, $last-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $first-color, $last-color);
    background: linear-gradient(to right, $first-color, $last-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $first-color, $last-color);
    background: radial-gradient(ellipse at center, $first-color, $last-color);
  }
}

// .example-gradient {
//   @include gradient(#333, #fff)
//   or
//   @include gradient(#333, #fff, vertical);
//   or
//   @include gradient(#333, #fff, horizontal);
// }


// Border Radius //
@mixin border-radius($top-left: null, $top-right: null, $bottom-right: null, $bottom-left: null) {
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}
