.card {
    &-top {
        width: 388.65px;
        height: 245.46px;
        background: transparent;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: #BAE7C6;
    }

    &-bottom {
        width: 100%;
        max-width: 297.65px;
        max-height: 268px;
        height: 100vh;
        background: #FFFFFF;
        border-radius: 0px 0px 20px 20px;
        padding: 39.77px 47.36px 0 43.36px;  
    }

    &-title {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #3B5277;
        margin-bottom: 18.23px;
    }

    &-description {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #3F5475;
        height: 105px;
        overflow: hidden;
    }

    &-img {
       width: 194.75px;
       height: 200.01px;
       margin-bottom: -5px;
       width: 100%;
       border-radius: 20px 20px 0px 0px;
        height: auto;
        height: 100%;
        object-fit: cover;
    }
}