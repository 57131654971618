.banner {
    display: flex;
    align-items: center;
    height: 100%;
    @include breakpoint('lg_down') {
      flex-direction: column;
      text-align: center;
      height: 800px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height:  100vh;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #7ABF8D 0%, #8DCF9F 26.2%);
      top: 105px;
      @media(max-height: 800px) {
        height: 905px;
     }
    }
  
    &-header {
        &-text {
            position: relative;
            z-index: 11;
            margin-top: 70px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            align-self: baseline;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include breakpoint('lg_up') {
              margin-top: 180px;
            }
            @include breakpoint('lg_down') {
              margin-right: 10%;
              width: 561px;
              margin-top: 76.62px;
            }
            @include breakpoint('md_down') {
             margin-right: 4%;
            }
            @include breakpoint('sm_down') {
              margin-right: auto;
            }
            @include breakpoint('xs_down') {
              width: auto;
              padding: 0 8px;
            }
            h1 {
                color: var(--color-white);
                margin: 0;
                position: relative;
                margin-bottom: 18.17px;
                max-width: 709.42px;
                min-height: 159.45px;
                font-size: 40px;
                min-height: 73.02px;
                @include breakpoint('lg_down') {
                  max-width: 706.94px;
                }

                @include breakpoint('sm_down') {
                  margin-bottom: 0.15px;
                }
               
                @include breakpoint('xxs_down') {
                  font-size: 28px;
                  line-height: 35px;
                  height: 73.02px;
                  min-height: unset;
                  margin: 0;
                }
                
                @keyframes animate {
                  0% {
                    width: 0;
                  }
                
                  100% {
                    width: 100%;
                  }
  
                  0% {
                    width: 0;
                  }
                }
  
                @keyframes animate1 {
                  0% {
                    opacity: 0;
                  }
                
                  100% {
                    opacity: 1;
                  }
                }
                
            }

          .description {
            max-width: 660px;
            margin-bottom: 71.63px;
            min-height: 69.08px;
            line-height: 28px;
            color: #26385C;

            & span {
              font-weight: 600;
            }

            @include breakpoint('lg_down') {
              margin: 0 auto 55.76px auto;
              max-width:623px;
            }
            @include breakpoint('md_down') {
              font-weight: 400;
              font-size: 20px;
              line-height: 26px;
            }
            @include breakpoint('sm_down') {
              margin: 0 auto 56.46px auto;
             
            }
            @include breakpoint('xxs_down') {
              height: 174.43px;
              font-weight: 400;
              font-size: 18px;
              line-height: 23px;
              text-align: center;
              width: 325.42px;

              br {
                display: none;
              }
              span {
                display: inline-block;
                margin-top: 30px;
              }
            }

          }

          .btn {  
            font-size: 18px;
            line-height: 18px;
            height: 58px;
            padding: 0 34.93px;
            font-weight: 400;
            width: 201px;
            background-color:  #26385C;
            cursor: pointer;
            color: var(--color-white);
            @include breakpoint('lg_down') {
              margin: auto;
              font-weight: 600;
              line-height: 26px;
            }
          }
          .btn--outline {
            border: 2px solid #31446A;
            width: 234px;
            height: 58px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
          }
        }
  
        &-image {
          z-index: 10;
          .bg-image {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 9;
          }
          .truck-img {
            position: absolute;
            bottom: 60px;
            left: 70.47px;
            height: 45%;
            z-index: 10;
            @include breakpoint('lg_down') {
             display: none;
           }
          }
          .truck-tablet-img {
            display: none;
            @include breakpoint('lg_down') {
             display: block;
             position: absolute;
             bottom: 35.3px;
             left: 70.75px;
             z-index: 10;
           }
            @include breakpoint('md_down') {
            width: 700px
            }
            @include breakpoint('sm_down') {
           display: none;
            }
          }
          .truck-mini-tablet-img {
            display: none;
            @include breakpoint('sm_down') {
              display: block;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: 9;
              }
              @include breakpoint('xxs_down') {
              display: none;
              }

          }
          .truck-mobile-img {
            display: none;
           @include breakpoint('xxs_down') {
            display: block;
            width: 335.69px;
            height: 253.99px;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: 9;
          }

          }


          .clouds-img {
            position: absolute;
            top: 181.51px;
            left: 0;
            right: 0;
            width: 100%;
            height: 60%;
            @include breakpoint('lg_down') {
              display: none;
            }
          }
          .clouds-tablet-img {
            display: none;
            @include breakpoint('lg_down') {
              position: absolute;
              bottom: 174.62px;
              left: 0;
              right: 0;
              width: 100%;
              display: block;
            }
            @include breakpoint('sm_down') {
              display: none;
            }
          }

          .clouds-mobile-img {
            display: none;
            @include breakpoint('sm_down') {
              position: absolute;
              bottom: 62px;
              left: 0;
              right: 0;
              display: block;
              width: 608.54px;
              height: 464.58px;
            }
            @include breakpoint('xxs_down') {
              min-width: 608.54px;
              height: 464.58px;
              left: 0;
            }
          }
       
        }
    }

  .triangle {
    display: flex;
    border-top: 20px solid var(--color-green-light);
    border-bottom: 15px solid transparent;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    cursor: pointer;
    animation: bounce .8s ease-in-out infinite;

    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-5px);
      }
    }
  }  

  &-wrap {
    height:100vh;
    min-height: 905px;
    @include breakpoint('lg_down') {
       height: 905px;
       min-height: unset;
    }
    @media(max-height: 800px) {
      height: 905px;
   } 
   @include breakpoint('md_down') {
    height: 950px;
   } 
   @include breakpoint('sm_down') {
    height: 905px;
   }
   @include breakpoint('xxs_down') {
    height: 822px;
   }
  }

  &-wrapper {
    position: relative;

    .next-section {
      z-index: 9;
      @include breakpoint('xxs_down') {
        height:90px;
        bottom: -40px;
       } 
    }
  }
}

.banner-container {
  position: relative;
  .arrow-navigatior {
    z-index: 98;
  }
}