/* =Common Styles
-------------------------------------------------------------- */
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    margin: 0;
    padding: 0;    
    position: relative;
    font-family: var(--body-font);
}

::selection {
    background: var(--color-primary);
    color: var(--color-white);
}
::-moz-selection {
    background: var(--color-primary);
    color: var(--color-white);
}

