.join {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    z-index: 999;
    position: relative;

    &-wrapper {
        background: linear-gradient(180deg, #080B11 0%, #171F2F 83.9%);
        position: relative;
        height: 1000px;
        position: relative;

        
        @include breakpoint('md_down') {
            height: 1700px;
        }
        @include breakpoint('xs_down') {
            height: 1270px;
        }
        @include breakpoint('xxs_down') {
            height: 1270px;
        }

        &::before {
            content: '.';
            background-image: url("../../img/home/the-final-destination/triangle.svg");
            display: block;
            width: 100%;
            background-repeat: no-repeat;
            position: absolute;
            z-index: 9;
            top: -4px;
            height: 100vh;
            left: 0;
            right: 0;
            background-size: contain;
        }
    }

    &-section {
        height: 100%;
        justify-content: center;

        @include breakpoint('sm_down') {
            justify-content: start;
        }

        .bg-stars-img {
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            position: absolute;

            @include breakpoint('sm_down') {
                top: 60px;
            }
        }

        .bar-wrapper-join {
            position: absolute;
            height: 359.81px;
            left: 0;
            right: 0;
            bottom: -300px;
        }

        .bar-img-join {
            width: 100%;
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        z-index: 99;
        padding-top: 183.59px;

        .header-text {
            color: var(--color-secondary);
            min-height: 84.71px;
            ;
            text-align: center;
            margin-bottom: 56.42px;
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;
            text-align: center;
            letter-spacing: 0.02em;
            margin-bottom: 21.33px;

            @include breakpoint('sm_down') {
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                min-height: unset;
                margin-bottom: 16.5px;
            }

            @include breakpoint('xxs_down') {
                font-weight: 600;
                font-size: 26px;
                line-height: 33px;
                text-align: center;
                letter-spacing: 0.02em;
                min-height: unset;

            }

        }

        .description {
            color: var(--color-white);
            line-height: 28px;
            margin-bottom: 80px;
            font-size: 22px;
            &>span {
                font-size: 30px;
                line-height: 37px;
            }

            @include breakpoint('sm_down') {
                max-width: 400px;
            }

            @include breakpoint('xs_down') {
                max-width: 370px;
            }
            @include breakpoint('xxs_down') {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                width: 289.16px;
                margin-bottom: 20px;

            }
        }

    }

    .boxes-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 400.77px);
        grid-auto-rows: minmax(100px, 200px);
        gap: 89px;
        margin: auto;
        z-index: 99;
        min-height: 200px;

        @include breakpoint('sm_down') {
            grid-template-columns: auto;

        }

        .box {
            background: #26385C;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            h6 {
                color: var(--color-white);
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 38px;
                text-align: center;
            }

            &--discord {
                .discord-icon {
                    margin-bottom: 8px;
                }
            }

            &--twitter {
                .twitter-icon {
                    margin-bottom: 8px;
                }
            }

        }
    }

    .boxes-bottom-part {
        display: flex;
        flex-direction: column;
        max-width: 829.53px;
        margin: auto;
        max-height: 319.9px;

        @include breakpoint('md_down') {
            width: 100%;
            max-width: 750px;
        }

        @include breakpoint('sm_down') {
            max-height: unset;
            gap: 24px;
            max-width: 400px;

        }

        .btn-white-listed {
            background: #E2857E;
            border-radius: 22.9012px;
            width: 100%;
            color: #26385C;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 25.1913px;
            min-height: 119.73px;
            display: flex;
            flex-direction: column;
            width: auto;

            @include breakpoint('sm_down') {
                height: 180px;
                width: 320px;

            }
            @include breakpoint('xxs_down') {
                max-width: 320px;
                font-size: 22px;
                line-height: 28px;
                padding: 0;
                margin: auto;

            }

            h6 {
                color: #26385C;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                text-align: center;
                @include breakpoint('xxs_down') {
                    font-size: 22px;
                    line-height: 28px;
    
                }
            }

            p {
                color: #26385C;
                font-weight: 600;
                font-size: 18px;
                @include breakpoint('xxs_down') {
                    font-size: 16px;
                    line-height: 20px;
    
                }

            }
        }
    }

    .boxes-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 400.77px);
        grid-auto-rows: minmax(100px, 171.76px);
        gap: 30px;
        margin: auto;
        z-index: 99;

        @include breakpoint('md_down') {
            grid-template-columns: repeat(2, 350px);
        }

        @include breakpoint('sm_down') {
            grid-template-columns: auto;
            gap: 20px;
            width: 320px;

        }
        @include breakpoint('xxs_down') {
            gap: 20px;
            width: 320px;

        }

        .box {
            background: #121D32;
            border-radius: 22.9012px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            height: 171.76px;

            @include breakpoint('sm_down') {
                height: 180px;

            }

            h6 {
                color: #26385C;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 38px;
                text-align: center;
                @include breakpoint('xxs_down') {
                    font-size: 22px;
                    line-height: 28px;
    
                }
            }

            p {
                color: #26385C;
                font-weight: 600;
                font-size: 18px;
                @include breakpoint('xxs_down') {
                    font-size: 16px;
                    line-height: 20px;
    
                }
            }

            &--discord {
                background-color: #A4DBB3;

                .discord-icon {
                    margin-bottom: 9.16px;
                }
            }

            &--twitter {
                background-color: #88CFDE;

                .twitter-icon {
                    margin-bottom: 9.16px;
                }
            }

        }
    }

}