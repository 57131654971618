.btn {
  min-width: 130px;
  min-height: 50px;
  border: 0;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 30px;
  @include transition(all 0.3s ease-in-out);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Proxima Nova', sans-serif;

  &--primary {
    @include button(secondary, white);
  }

  &--green {
    @include button(green, white);
    min-height: 40px;
  }

  &--greenish {
    @include button(greenish, blackish3);
    min-height: 40px;
  }
  &--secondary {
    font-size: 16px;
    min-height: 40px;
    @include button(primary, white);
    padding: 0 15px;
    min-width: 100px;
  }

  &--outline {
    min-height: 56px;
    @include button(transparent, primary);
    border: 1px solid #3F5475;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    &--green {
      @include button(transparent, green);
      border: 1px solid var(--color-green);
    }

    &--blackish {
      @include button(transparent, blackish);
      border-color: var(--color--blackish);
      
    }

    &--blue-lighter {
      @include button(transparent, blue-lighter);
      border-color: var(--color-blue-lighter); 
    }

    &--secondary {
      @include button(transparent, secondary);
      border-color: var(--color-secondary); 
    }

    &--white {
      @include button(transparent, white);
      border-color: var(--color-white); 
      color: var(--color-white); 
    }

  }
}
