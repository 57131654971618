.modal-content {
    &-form {
        @include display (flex, center, center, column);
        position: relative;
        
        
        .close-icon {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;

            circle, 
            path {
                stroke-width: 2px;
                stroke: var(--color-gray);
                transition: 0.2s all ease-in-out;
            }

            path {
                stroke-width: 1px;
            }

            &:hover {
                circle, 
                path {
                    stroke: var(--color-primary);
                }
            }
        }
        
        &-inputs {
            width: 795px;
            
            h3 {
                color: var(--color-green);
            }

            &-inner {
                width: 100%;

                .modal-content-buton {
                    width: 100%;
                    @include display (flex, center, space-between, initial);

                    span {
                        font-family: 'Proxima Nova';
                        color: var(--color-primary);
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }

            .label-singup {
                min-width: 280px;

                label {
                    max-width: 188px;
                }
            }
        }

    }

    input, textarea{
        width: 100%;
    }
}

.modal-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 50px;
    border-radius: 0 40px;
    max-height: 80vh;
    overflow-y: auto;
}
  
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(28, 41, 66, 0.85);
    overflow: hidden;
    z-index: 999;
}
  
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}