.the-story {
    display: flex;
    height: 960.8px;
    gap: 40.31px;
    padding-left: 50px;
    

    @include breakpoint('sm_down') {
        flex-direction: column;
        height: 100%;
        padding: 0;
        gap: 94.01px;
    }
    @include breakpoint('xxs_down') {
        gap: 0;
       }
  


    &-wrapper {
        position: relative;
        background-color: var(--color-black2);
        height: 960.8px;
        z-index: 10;
        @include breakpoint('lg_down') {
            height: 894px;
         }
        @include breakpoint('sm_down') {
          height: 1392px;
        }
        @include breakpoint('xs_down') {
            height: 1470px;
           }
        @include breakpoint('xxs_down') {
            height: 1168px;
           }
    }

    &-text {
        display: flex;
        flex-direction: column;
        max-width: 491px;
        margin-top: 138.8px;

        @include breakpoint('lg_down') {
            max-width: 350px;
            margin-top: 86.06px;
        }
        @include breakpoint('sm_down') {
            text-align: center;
            max-width: 500px;
           margin: 96.27px auto 0 auto;
        }
        @include breakpoint('xs_down') {
          padding: 0 50px;
        }
        @include breakpoint('xxs_down') {
            max-width: 370px;
            padding: 0 16px;
        }

        .header-text {
            min-height: 50px;
            margin-bottom: 62.11px;
            color: var(--color-white);

            @include breakpoint('lg_down') {
                margin-bottom: 75.77px;
            }
            @include breakpoint('sm_down') {
                margin-bottom: 75.77px;
            }
            @include breakpoint('xs_down') {
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
            }
            @include breakpoint('xxs_down') {
                font-weight: 600;
                font-size: 28px;
                line-height: 35px;
                margin-bottom: 47.08px;
             }
        }

        .description {
            line-height: 30px;
            color: #A4DBB3;

            @include breakpoint('lg_down') {
                line-height: 28px;
            }
            @include breakpoint('sm_down') {
                text-align: left;
            }
            @include breakpoint('xs_down') {
                font-weight: 400;
                font-size: 22px;
                line-height: 26px;
            }
            @include breakpoint('xxs_down') {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                width: 320px;
             }
        }


        .btn {
            width: 245px;
            height: 56px;
        }
    }

    &-img {
        margin-top: 114.05px;
        width: 55%;
        margin-left: auto;

        @include breakpoint('lg_down') {
            margin-top: 217px;
            max-width: 635.88px;
            max-height: 705.41px;
        }

        @include breakpoint('sm_down') {
          width: 100%;
          margin: 0 auto;
          max-width: unset;
          max-height: unset;
          display: flex;
          justify-content: center;
        }
     
          @include breakpoint('xxs_down') {
            margin-top: 70px;
           
          }
          .room-dark-img {
            transition: all .3s ease-out;
          }
          .room-img {
            transition: all .3s ease-in;
          }
        img {
           
            @include breakpoint('sm_down') {
                min-width: 797.1px;
              height: 692.13px;
            }
            @include breakpoint('xs_down') {
                min-width: 650px;
               
            }
            @include breakpoint('xxs_down') {
              min-width: 518.31px;
              height: 450.05px;
             }
        }

        &-bee {
            margin-top: 60px;
            margin-bottom: 78px;
            margin-left: auto;
            z-index: 999;

            @include breakpoint('lg_down') {
                margin: 0;
                position: absolute;
                bottom: -18px;
                left: 38%;
            }
            @include breakpoint('sm_down') {
               display: none;
            }
        }
    }
}

.wrapper-of-carousel {
    background-color: #344258;
}

