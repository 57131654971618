.first-mission-revealed {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;

    @include breakpoint('md_down') {
        grid-template-columns: auto;
    }
 
    &-image {
        position: relative;
        @include display(flex, center);
        height: 100%;

        .lion-image {
            margin-top: auto;
            z-index: 99;

            @include breakpoint('lg_down') {
                margin-left: -79.4px;
            }
            @include breakpoint('md_down') {
                width: 380px;
            }
            @include breakpoint('sm_down') {
                width: 475px;
                height: 446px;
                margin-left: 0;
                margin-bottom: -12px;
            }
            @include breakpoint('xxs_down') {
                margin-bottom: 145px;
            }
        }

        @include breakpoint('md_down') {
            order: 2;
            justify-content: center;
        }
    }

    .map-line-image {
        position: absolute;
        top: 171.52px;
        right: 0;

        @include breakpoint('lg_down') {
            width: 783.88px;
            height: 485.96px;
            top: unset;
            bottom: 50px;
            right: -58px;
        }
        @include breakpoint('sm_down') {
            width: 600px;
            height: 550px;
            bottom: 0;
        }
    }

    &-content {
        @include breakpoint('md_down') {
            z-index: 9;
        }

        &-description {
            padding-left: 50px;
        
            @include breakpoint('lg_down') {
                max-width: 350px;
            }
            
            @include breakpoint('md_down') {
                margin-top: 80px;
             }
         
            @include breakpoint('sm_down') {
                margin: auto;
                max-width: 500px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            @include breakpoint('xs_down') {
                padding: 0 8px 0 16px;
                width: 370px;
            }
         

            .header-text {
                color: #26385C;
                min-height: 50px;
                margin-bottom: 59.19px;
                font-weight: 600;
                font-size: 40px;
                @include breakpoint('lg_down') {
                    margin-bottom: 59.63px;
                }
                @include breakpoint('xxs_down') {
                    font-size: 28px;
                line-height: 35px;
                text-align: center;
                margin-bottom: 36.7px;
                }
              
            }

            .description {
                color: #26385C;
                max-width: 524.31px;
                line-height: 26px;
                margin-bottom: 88.34px;
                @include breakpoint('lg_down') {
                    margin-bottom: 50px;
                }

                @include breakpoint('xxs_down') {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    width: 320px;
                }
                ul {
                    min-height: 210px;
                    margin-bottom: 22.08px;
                    margin-left: 24px;
                    @include breakpoint('sm_down') {
                        margin: 0;
                    }
                    li {
                        list-style-type: disc;
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 30px;
                        letter-spacing: 0.02em;
                        color: #26385C;
                        margin-bottom: 16px;
                        @include breakpoint('xxs_down') {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                        }
                    }
                }
            }

            .btn-wrapper {
                display: flex;
                gap: 51.18px;

                .btn {
                    height: 56px;
                    width: 245px;
                    background-color: #26385C;
                    color: var(--color-white);
                    line-height: 26px;
                    font-weight: 600;
                }

                .btn--outline {
                    width: 241px;
                }
            }

       
        }
    }



    &-section {
        height: 905px;
      
        @include breakpoint('md_down') {
            height: 1100px;
        }
        @include breakpoint('sm_down') {
            height: 1450px;
        }
        .mini-title {
            margin-top: 43.63px;
        }
    }

    &-wrapper {
        background: linear-gradient(180deg, #7CBDCB 0%, #88CFDE 27.04%);
        position: relative;
      
        @include breakpoint('xxs_down') {
            height: 1270px;
         }
     
        .next-section {
            background: #182339;
            z-index: 9;
        }

        .title-right {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            text-align: right;
            color: #31446A;
            position: absolute;
            right: 94.17px;
            top: 143.26px;
            z-index: 1;

            @include breakpoint('sm_down') {
                top: unset;
                bottom: 600px;
                right: 50%;
                transform: translateX(50%);
            }
            @include breakpoint('xs_down') {
               bottom: 520px;
               width: 300px;
               text-align: center;
            }
            @include breakpoint('xxs_down') {
                bottom: 480px;
                font-size: 28px;
                line-height: 35px;
                text-align: center;
            }
        }
    }
}