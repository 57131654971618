/*// -----------------------------------------------------------------------------
// This file contains all styles related to the Header of the site/application.
// -----------------------------------------------------------------------------
*/

/* =Header Styles
-------------------------------------------------------------- */
.header {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  height: 105px;
  
  @include breakpoint('lg_down') {
    position: relative;
    top: initial;
  }

  &-logo {
    padding-left: 48.35px;
    @include breakpoint('lg_down') {
      padding-left: 81.41px;
    }
    @include breakpoint('md_down') {
      padding-left: 28px;
    } 
    img {
      margin-top: -20px;
    }
  }

  .nav-menu {
    align-self: flex-end;
    margin-bottom: 34.16px;

    @include breakpoint('lg_down') {
      background: white;
			flex-direction: column;
			width: 100%;
			position: fixed;
			left: 0;
			height: 100%;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateX(-100%);
			transition: .3s all ease-in-out;
			transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    }


    ul {
      display: flex;
      align-items: center;
      @include breakpoint('lg_down') {
        flex-direction: column;
      }

      li {
        display: inline-flex;
        @include breakpoint('lg_down') {
          margin-bottom: 20px;
        }
        &:not(:last-child) {
          margin-right: 60px;
          @include breakpoint('lg_down') {
            margin-right: 0;
          }
        }

        a {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: var(--color-primary);
          @include breakpoint('lg_down') {
            font-size: 18px;
          }
          &:hover {
            color: var(--color-white);
            @include breakpoint('lg_down') {
              color: var(--color-black);
            }
          }
          &.disabled {
            cursor: default;
            pointer-events: none;
            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
      .active {
        a {
          color: var(--color-white);
          @include breakpoint('lg_down') {
            color: var(--color-black);
          }
        }
      }
    }

    @include breakpoint('lg_down') {
      &.active {
				transform: translateX(0);
          ul {
              li {
                  opacity: 0;
                  animation: fadeInUp 0.6s 1;
                  animation-fill-mode: forwards;

                  &:nth-child(4n+1) { 
                      animation-delay: 0.2s;
                  }

                  &:nth-child(4n+2) { 
                      animation-delay: 0.3s;
                  }

                  &:nth-child(4n+3) { 
                      animation-delay: 0.4s;
                  }

                  &:nth-child(4n+4) {
                      animation-delay: 0.5s;
                  }
              }
          }
			}

    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    padding-right: 48.07px;
    @include breakpoint('md_down') {
      padding-right: 30px;
    } 
    .btn {
      padding: 0 15px;
      min-width: 100px;
      font-size: 14px;
      @include breakpoint('md_down') {
        display: none;
      } 
    }
  }

  &.header-get-started {
    ul {
      li {
        a {
          color: var(--color-green);
        }
      }
    }

    .header-buttons { 
      
      .btn {
        &--outline {
          @include button(transparent, green);
          border: 1px solid var(--color-green);
          font-family: 'Proxima Nova';
        }

        &--secondary {
          @include button(green, white);
        }
      }
    }
  }

  @include breakpoint('lg_up') {

  &-green {
    .nav-menu {
      ul {
        li {
  
          a {
            color: var(--color-green);
  
            &:hover {
              color: var(--color-white);
            }
          }
        }
        .active {
          a {
            color: var(--color-white);
          }
        }
      }
    }
    .btn {
      border-color: var(--color-green);
      background-color: var(--color-green);
    }
    
    .btn--outline {
      color: var(--color-green);
      background-color: transparent;
    }
  }
}

  .btn--secondary {
    width: auto;
    height: 56px;
    background:  #26385C;
    border-radius: 40px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;  
    line-height: 24px;
    padding: 0 35.5px;
  }
}


/* Hamburger Button Menu*/
.main-menu-indicator {
	display: none;
	padding: 0;
	cursor: pointer;
	z-index: 9;
	height: 50px;
	width: 36px;
	background: transparent;
	outline: 0;
	float: right;
	position: relative;
	margin: 0 0 0 26.44px;

  @include breakpoint('xs_down') {
    display: block;
  }

	@include on-event ($self: false) {
	  box-shadow: none;
	}

	> span {
		transition: all 0.1s ease 0s;
		display: block;
		position: relative;
		width: 36px;
		height: 3px;
		margin: auto;
		background: #3F5475;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before,
		&:after {
			transition: all 0.2s ease 0s;
			position: absolute;
			content: '';
			width: 36px;
			height: 3px;
			background: #3F5475;
			left: 0;
		}

		&:after {
			top: -7px;
		}

		&:before {
			bottom: -7px;
		}
	}

	&.active > span {
		background: transparent;

		&:after {
			top: 0;
			transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			background: var(--color-primary);
		}

		&:before {
			bottom: 0;
			transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			background: var(--color-primary);
		}
	}
}

.header-get-started {
  .main-menu-indicator { 
    span {
      background-color: var(--color-white);

      &::before, 
      &::after {
        background-color: var(--color-white);
      }
    }

    &.active > span { 
        background: transparent;
        
        &::before, 
        &::after {
          background: var(--color-primary);
        }
    }
  }
}

.header-logo {
  padding-top: 11.38px;
}

.main-menu-indicator {
  //@include breakpoint('lg_down') {
  //  display: block;
  //}
}