.start_legend {
    &-section {
        text-align: center;
        justify-content: flex-end;
        height: 350px;
        @include breakpoint('xs_down') {
            height: 339px;
          }
    }

    &-wrapper {
        background: linear-gradient(180deg, #1C263A 0%, #293856 52.14%);
        position: relative;
        height: 438px;
        @include breakpoint('xlg_down') {
            height: 235px;
         }
         @include breakpoint('xs_down') {
            height: 76px;
         }

        .road-img-truck {
            position: absolute;
            top: -44px;
            left: 0;
            right: 0;
            width: 100%;
            @include breakpoint('xs_down') {
               display: none;
             }
        }

        .road-mob-img-truck {
            position: absolute;
            display: none;
            left: 0;
            right: 0;
            width: 100%;
            @include breakpoint('xs_down') {
               top: -198px;
               display: block;
             }
             @include breakpoint('xxs_down') {
                top: -160px;
                display: block;
              }
        }

        .header-text {
            color: var(--color-white);
            min-height: 100px;
        }

        .boxes-bottom-part {
            display: flex;
            flex-direction: column;
            gap: 47.69px;
            width: 859.94px;
            margin: auto;
            @include breakpoint('md_down') {
                width: 100%;
              }
            .btn-white-listed {
                background: #E2857E;
                border-radius: 22.9012px;
                width: 100%;
                color: #26385C;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 25.1913px;
                height: 129.82px;
                display: flex;
                flex-direction: column;
                max-width: 800px;
                h6 {
                    color: #26385C;
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    text-align: center;
                }
                p {
                    color: #26385C;
                    font-weight: 600;
                    font-size: 16px;

                }
            }
        }

        .boxes-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 400px);
            grid-auto-rows: minmax(100px, 171.76px);
            gap: 58.4px;
            margin: auto;
            z-index: 99;
            @include breakpoint('md_down') {
                grid-template-columns: repeat(2, 350px);
              }
            .box {
                background: #121D32;
                border-radius: 22.9012px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                height: 171.76px;

                h6 {
                    color: #26385C;
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: center;
                }
                p {
                    color: #26385C;
                    font-weight: 600;
                }

                &--discord {
                    background-color: #A4DBB3;
                    .discord-icon {
                        margin-bottom: 9.16px;
                    }
                }

                &--twitter {
                    background-color: #88CFDE;
                    .twitter-icon {
                        margin-bottom: 9.16px;
                    }
                }

            }
        }
    }

    &-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
        padding-bottom: 88.32px;

        .btn {
            width: 100%;
            height: 164.04px;
            background: #D9D9D9;
            border-radius: 20px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
         
        }
    }
}