/* =Typography
-------------------------------------------------------------- */

a {
  color: inherit;
  text-decoration: none;
  transition: .3s all ease-in-out;
  
  &:focus {
    outline: none;

  }
}

ul {
  margin: 0;
  padding: 0;
  
  li {
    list-style: none;
  }
}

em {
  font-style: italic;
}

small {
  font-size: 10px;
}

p {
  clear: both;
  letter-spacing: 0.02em;
  margin: 0;
}

h1 {
  font-size: 70px;
}
h2 {
  font-size: 50px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

b {
  font-weight: 700;
}

button {
  border: 0;
  outline: none;
}
