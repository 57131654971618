.footer {
  padding:0;
  // min-height: 182.46px;
  height: 239px;
  @include display(flex, center, space-between, initial);
  @include breakpoint('xlg_down') {
    padding: 0 24px 0 24px;
   }
  @include breakpoint('lg_down') {
   padding: 0 76px 0 81px;
  }

  @include breakpoint('md_down') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    gap: 16px;
}
@include breakpoint('xxs_down') {
  height: 432.24px;
 }

  .primary {
    @include breakpoint('lg_down') {
      margin: 0 auto;
    }
    @include breakpoint('md_down') {
      white-space: nowrap;
    }
  }

  &-social-media {
    &-icon {
      margin-left: 15px;

      svg,
      circle {  
        transition: .25s all ease-in-out;
        cursor: pointer;
      }
      
      &:hover {
        svg,
        circle {
          fill: var(--color-green);
          stroke: var(--color-green);
        }
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    padding-bottom: 91.12px;
    gap: 41.7px;

     @include breakpoint('md_down') {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding:0;
      align-self: center;
  }

  @include breakpoint('xxs_down') {
    flex-direction: column-reverse;
    margin-top: 104px;
   }

 
  }

  &-wrapper {
   background-color: #121D32 !important;
   z-index: 9;
   position: relative;
   max-height: 239px;
   @include breakpoint('xxs_down') {
    max-height: 432.24px;
   }
   .container {
    height: 239px;
    @include breakpoint('xxs_down') {
      height: 432.24px;
     }

   }
  }

  .powered {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: var(--color-white);
    // height: 44.09px;

    @include breakpoint('md_down') {
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }
}
