.banner-belief {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 189.3px;
    @include breakpoint('lg_down') {
      flex-direction: column;
      text-align: center;
      height: 895px;
    }
    @include breakpoint('md_down') {
      height:100%;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height:  100vh;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #7CBDCB 0%, #88CFDE 27.04%);
      top: 105px;
      @media(max-height: 800px) {
        height: 905px;
     }
    }
  
    &-header {
        &-text {
            position: relative;
            z-index: 4;
            margin-top: 296.46;
            @include breakpoint('md_down') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            h1 {
                color: black;
                margin: 0;
                position: relative;
                margin-bottom: 46.76px;
                max-width: 748.35px;
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
                min-height: unset;
                @include breakpoint('lg_down') {
                  margin-top: 68.09px;
                  max-width: 706.94px;
                }

                @include breakpoint('md_down') {
                  font-weight: 600;
                  font-size: 30px;
                  line-height: 38px;
                  max-width: 317.07px;
                  margin-top: 68px;
                  text-align: left;

                } 
        

                span {
                  color: var(--color-white);
                }
                        
                
                @keyframes animate {
                  0% {
                    width: 0;
                  }
                
                  100% {
                    width: 100%;
                  }
  
                  0% {
                    width: 0;
                  }
                }
  
                @keyframes animate1 {
                  0% {
                    opacity: 0;
                  }
                
                  100% {
                    opacity: 1;
                  }
                }
                
            }

          .description {
            max-width: 581px;
            margin-bottom: 40px;
            color: #1E2A41;
            @include breakpoint('lg_down') {
              margin: 30px auto 40px auto;
              max-width:623px;
            }
            @include breakpoint('md_down') {
              font-weight: 400;
              font-size: 20px;
              line-height: 26px;
            }
          }

          .btn {  
            font-size: 18px;
            line-height: 18px;
            height: 44px;
            padding: 0 34.93px;
            font-weight: 400;
            @include breakpoint('lg_down') {
              margin: auto;
              font-weight: 600;
              line-height: 26px;
            }
          }
        }
  
        &-image {
            z-index: 3;
            position: relative;
            height: 100%;
            display: flex;
            
            @include breakpoint('lg_down') {
              height: unset;
              margin-top: auto; 
             }
             
             @include breakpoint('md_down') {
              margin-bottom: auto;
              position: absolute;
              width: 100%;
              display: flex;
              justify-content: center;
              height: 100%;
              top: 0;
              overflow: hidden;
              margin-top: 10px;
            }
            .leaf {
              min-height: 549.76px;
              max-width: 570.39px;
              margin-top: auto;
              margin-bottom: 186.96px;
              @include breakpoint('lg_down') {
                width: 441.44px;
                height: 425.48px;
                min-height: unset;
                margin: unset;  
              }

              @include breakpoint('md_down') {
                margin-top: auto;
                margin-bottom: 135.52px;
                width: 441px
              }
            }
        }
    }



  &-wrap {
    height:100vh;
    min-height: 905px;
    @include breakpoint('lg_down') {
       height: 1105px;
       min-height: unset;
    }
    @media(max-height: 800px) {
      height: 905px;
   } 
   @include breakpoint('md_down') {
    height: 806.66px;
   } 
  }

  &-wrapper {
    position: relative;
  }
}

//Who We are Section
.section2-who-we-are {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    @include breakpoint('md_down') {
        grid-template-columns: auto;
    } 
    
    &-image {
        position: relative;
        @include display(flex, center);
        height: 100%;
        @include breakpoint('md_down') {
            order: 2;
            justify-content: center;
        } 
    }

    &-content {
        @include breakpoint('md_down') {
            z-index: 9;
        }
        &-description {     
            @include breakpoint('lg_down') {
                max-width: 422px;
            }   
            @include breakpoint('md_down') {
                padding-left: 31px;
                width: 315.74px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: left;
            }     
            .header-text {
                color: var(--color-white);
                min-height: 63px;
                margin-bottom: 42px;
                max-width: 561px;
                @include breakpoint('md_down') {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: left;
                    min-height: 38px;
                    align-self: baseline;
                } 
            }
            .description {
                color: var(--color-green);
                max-width: 612.06px;
                line-height: 26px;  
                margin-bottom: 63.65px;
            
                @include breakpoint('md_down') {
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 30px;
                } 
            }

            .btn {
                border-radius: 40px;
                width: 252px;
                height: 56px;
                @include breakpoint('lg_down') {
                    margin: 0 0 0 auto;
                }
                @include breakpoint('md_down') {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }



   &-section {
    min-height: 852px;
    @include breakpoint('lg_down') {
        height: 1105px;
    }
    @include breakpoint('md_down') {
        height: 885px;
        min-height: unset;
    }
    .mini-title {
        margin-top: 43.63px;
    }


   }

   &-wrapper { 
    position: relative;
    .next-section {
        background-color: #496287;
    }
   }
}

//Section 3
.section3goal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    @include breakpoint('md_down') {
        grid-template-columns: auto;
    } 
    
    &-image {
        position: relative;
        @include display(flex, center);
        height: 100%;
        @include breakpoint('md_down') {
            order: 2;
            justify-content: center;
        } 
    }

    &-content {
        @include breakpoint('md_down') {
            z-index: 9;
        }
        &-description {     
            @include breakpoint('lg_down') {
                max-width: 422px;
            }   
            @include breakpoint('md_down') {
                padding-left: 31px;
                width: 315.74px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: left;
            }     
            .header-text {
                color: var(--color-white);
                min-height: 63px;
                margin-bottom: 44px;
                max-width: 593px;
                @include breakpoint('md_down') {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: left;
                    min-height: 38px;
                    align-self: baseline;
                } 
            }
            .description {
                color: var(--color-blue-light);
                max-width: 525.69px;
                line-height: 26px;  
                margin-bottom: 88.34px;
            
                @include breakpoint('md_down') {
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 30px;
                } 
            }

            .btn {
                border-radius: 40px;
                width: 252px;
                height: 56px;
                background-color: #1E2A41;
                color: var(--color-green);
                @include breakpoint('lg_down') {
                    margin: 0 0 0 auto;
                }
                @include breakpoint('md_down') {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }



   &-section {
    min-height: 852px;
    @include breakpoint('lg_down') {
        height: 1105px;
    }
    @include breakpoint('md_down') {
        height: 885px;
        min-height: unset;
    }
    .mini-title {
        margin-top: 43.63px;
    }


   }

   &-wrapper { 
    position: relative;
   }
}

//Section 4
.section4-what-we-do {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    @include breakpoint('md_down') {
        grid-template-columns: auto;
    } 
    
    &-image {
        position: relative;
        @include display(flex, center);
        height: 100%;
        @include breakpoint('md_down') {
            order: 2;
            justify-content: center;
        } 
    }

    &-content {
        @include breakpoint('md_down') {
            z-index: 9;
        }
        &-description {     
            @include breakpoint('lg_down') {
                max-width: 422px;
            }   
            @include breakpoint('md_down') {
                padding-left: 31px;
                width: 315.74px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: left;
            }     
            .header-text {
                color: var(--color-white);
                min-height: 63px;
                margin-bottom: 44px;
                max-width: 561.34/px;
                @include breakpoint('md_down') {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: left;
                    min-height: 38px;
                    align-self: baseline;
                } 
            }
            .description {
                color: var(--color-blue-light);
                max-width: 525.69px;
                line-height: 26px;  
                margin-bottom: 88.34px;
            
                @include breakpoint('md_down') {
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 30px;
                } 
            }

            .btn {
                border-radius: 40px;
                width: 252px;
                height: 56px;
                background-color: #1E2A41;
                color: var(--color-green);
                @include breakpoint('lg_down') {
                    margin: 0 0 0 auto;
                }
                @include breakpoint('md_down') {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }



   &-section {
    min-height: 852px;
    @include breakpoint('lg_down') {
        height: 1105px;
    }
    @include breakpoint('md_down') {
        height: 885px;
        min-height: unset;
    }
    .mini-title {
        margin-top: 43.63px;
    }


   }

   &-wrapper { 
    position: relative;
   }
}