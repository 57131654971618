.missionary-boxes-wrapper {
    display: flex;
    gap: 56.04px;
    margin-top: 55.28px;
    @include breakpoint('xlg_down') {
      padding: 0 16px;
      margin: 0 auto;
    }
    @include breakpoint('md_down') {
       flex-wrap: wrap;
       padding: 24px;
       gap: 105.15px;
    }
    @include breakpoint('xs_down') {
       gap: 80px;

    &--wheel {
        @include breakpoint('xs_down') {
           gap: 40px !important;
        }
    }
    }

    .missionary-box {
        position: relative;
        border-radius: 22.9012px;
        background: #E2857E;
        width: 419.39px;
        height: 288.12px;
        align-items: flex-start;
        display: flex;
        
        @media  (max-width: 1512px) and (max-height: 960px) {
            width: 340px;
          }
        @include breakpoint('md_down') {
            margin: 0 auto;
         }

         @include breakpoint('xs_down') {
            width: 320px;
            height: 148.75px;
         }
        &::before {
            content:"\A";
            border-style: solid;
            border-width: 30px 40px 30px 0;
            border-color: transparent #E2857E transparent transparent;
            position: absolute;
            right: -36px;
            transform: translateY(-50%) rotate(180deg);
            top: 50%;
            @include breakpoint('md_down') {
                top: unset;
                bottom: -151px;
                left: 50%;
                transform: translateX(-50%) rotate(270deg);

             }
             @include breakpoint('xs_down') {
                bottom: -127px;

             }
        }

        &--green {
            background: #8DCF9F;
            &::before {
                border-color: transparent #8DCF9F transparent transparent;
            }

            .missionary-box-inside {
                margin: auto;
            }
        }

        &--blue {
            background: #88CFDE;
            &::before {
                content: none;
            }
            .missionary-box-inside {
                margin: auto;
            }
        }

        &-number {
            position: absolute;
            top: -31.125px;
            left: 50%;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            color: #26385C;
            width: 63.25px;
            height: 63.25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            transform: translateX(-50%);
            @include breakpoint('xs_down') {
                width:  40px;
                height: 40px;
                font-size: 22px;
                top: -20px;
            }
          
        }

        &-inside {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 61.92px 48px 0 48px;
            @include breakpoint('xlg_down') {
                padding: 61.92px 24px 0 24px;
              }
            @include breakpoint('lg_down') {
                padding: 8px;
                margin: auto;
            }
            @include breakpoint('xs_down') {
               padding-top: 30px;
            }
            
            h1 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                color: #26385C;
                @include breakpoint('md_down') {
                   max-width: 300px;
                 }
                 @include breakpoint('xs_down') {
                    font-size: 22px;
                    line-height: 28px;
                  }
            }

            .whitelist-icon {
                margin-top: 40px;
                @include breakpoint('xs_down') {
                  width: 35px;
                  margin-top: 10px;
                  }
            }
            .discord-icon {
                margin-top: 40px;
                width: 84.14px;
                height: 65.63px;
                @include breakpoint('xs_down') {
                    width: 40px;
                    margin-top: 20px;
                    height: 40px;
                    }
            }
            .twitter-icon {
                margin-top: 40px;
                width: 75.54px;
                height: 61.38px;
                @include breakpoint('xs_down') {
                    width: 40px;
                    margin-top: 20px;
                    height: 40px;
                    }
            }
        }
    }

    .wheel-card {
        @include breakpoint('xs_down') {
            width: 65vw;
         }
    }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1);}
    100% { transform: scale(1); }
}
  
.pulse:hover{ 
    animation: pulse .7s;
    animation-timing-function: linear;   
}