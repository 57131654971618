
// Proxima Nova Font //
@font-face {
  font-family: Proxima Nova;
  src: url("../fonts/ProximaNova-Regular.otf") format('truetype');
  font-weight: 400;
}

// Outfit Font //
@font-face {
  font-family: Outfit;
  src: url("../fonts/Outfit-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Outfit;
  src: url("../fonts/Outfit-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Outfit;
  src: url("../fonts/Outfit-Bold.ttf") format('truetype');
  font-weight: 700;
}

// Base
@import 'base/__base-dir';

// Variables
@import 'abstracts/__abstracts-dir';

// Layout
@import 'layout/__layout';

// Components
@import 'components/__components-dir';

// General
@import 'general';

// Pages
@import 'pages/__pages-dir';

//@import 'layout/_responsive';
