.input-wrap {
    display: flex;
    input,
    textarea {
        font-family: 'Proxima Nova';

        &::placeholder {
            color: var(--color-blue-soft);
            font-size: 16px;
        }
    }

    textarea {
        min-height: 123px;
        padding: 16px;
        resize: none;
        font-family: 'Proxima Nova';
    }
}

label {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-primary);
}

.error-message {
    font-size: 14px;
    color: red;
    padding-left: 6px;
    margin-top: 5px;
}