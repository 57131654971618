.the-final-destination {
    display: flex;
    align-items: center;
    height: 937.25px;

    @include breakpoint('lg_down') {
      align-items: baseline;
     }
    @include breakpoint('sm_down') {
        align-items: center;
        flex-direction: column-reverse;
        gap: 200px;
     }
   &-section {  
    @include breakpoint('lg_down') {
        justify-content: center;
        height: 1175px;
     }
     @include breakpoint('sm_down') {
        justify-content: end;
        height: 1588px;
     }
     @include breakpoint('xxs_down') {
        height: 1210px;
     }
    
   }
    &-wrapper {
        background: linear-gradient(180deg, #0F1520 0%, #1C2B47 30.93%);
       position: relative;
       margin-top: -2px;
       max-height: 937.25px;
       overflow: hidden;
        z-index: 10;
      
       @include breakpoint('lg_down') {
        height: 792px;
     }
     @include breakpoint('sm_down') {
        max-height: unset;
        height: 1465px;
      }
      @include breakpoint('xxs_down') {
        height: 1200px;
     }
     
    }
    .top-bar-img {
        width: 100%;
        @include breakpoint('lg_down') {
            display: none;
         }
    }
    .top-bar-img-tablet {
        display: none;
        @include breakpoint('lg_down') {
            display: block;
         }
    }
    .bar-wrapper-image {
        position: absolute;
        top: -234.5px;
        left: 0;
        right: 0;
        z-index: 9;
        @include breakpoint('lg_down') {
            top: -250px;
         }
         @include breakpoint('md_down') {
            top: -135px;
         }
         @include breakpoint('xxs_down') {
            top: -96px;
         }
    }
    &-text {
        z-index: 9;
        padding-top: 244.91px;
        max-width: 661.6px;
        @include breakpoint('xlg_down') {
            max-width: 550px;
            padding-top: 0;
            margin: auto;
            padding-right: 36px;
         }
        @include breakpoint('lg_down') {
            max-width: 400px;
            padding-top: 0;
            margin: auto;
            padding-right: 24px;
            margin-top: 120px;
         }
         @include breakpoint('md_down') {
            max-width: 350px;
            padding: 24px;
         }
         @include breakpoint('sm_down') {
            max-width: 500px;
            padding: 0 46.01px 0 55.45px;
         }
         @include breakpoint('xxs_down') {
            max-width: 370px;
            padding: 0 16px;
          }
        .header-text {
            color: #E59C96;
            min-height: 100px;
            margin-bottom:0;
            @include breakpoint('lg_down') {
                margin-bottom: 66.65px;
             }
             @include breakpoint('sm_down') {
               text-align: center;
               max-width: 328px;
               margin: 20px auto 41.34px auto;
             }
             @include breakpoint('xs_down') {
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
            }
             @include breakpoint('xxs_down') {
                font-weight: 600;
                font-size: 28px;
                line-height: 35px;
                margin-bottom: 49px;
            
               }
        }

        .description {
            color: #E59C96;
            @include breakpoint('xs_down') {
                font-weight: 400;
                font-size: 22px;
                line-height: 26px;
            }
            @include breakpoint('xxs_down') {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                width: 320px;
               }
            ul {
                li {
                    list-style-type: disc;
                    margin-bottom: 16px;
                    @include breakpoint('xxs_down') {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                        width: 320px;
                       }
                }
            }
        }

        .btn--secondary {
            width: 245px;
            height: 56px;
            color: var(--color-blackish3);
            background-color: var(--color-secondary);
        }
    }

    &-image {
        z-index: 9;
        width: 100%;
        @include breakpoint('lg_down') {
            width: 470.93px;
            }
        @include breakpoint('sm_down') {
            position: relative;
            margin-left: -100px;
            }
            @include breakpoint('xxs_down') {
                margin-left: 0;
                }
        img {
            height: 860.68px;
            width: 613.81px;
            margin-left: -34%;
            margin-top: 138px;
        }
    }

    .safe-img {
        width: unset;
        height: unset;
        position: absolute;
        top: -140px;
        left: -80px;
        @include breakpoint('xlg_down') {
            top: -140px;
             left: -260px;
            }
        @include breakpoint('lg_down') {
         display: none;
         }
    }
    .safe-img-tablet {
        display: none;
        @include breakpoint('lg_down') {
         display: block;
         width: 470.93px;
         height: 739.67px;
         margin: 0;
         }
         @include breakpoint('sm_down') {
            width: 488.58px;
            height: 774.31px;
          }
          @include breakpoint('xxs_down') {
            width: 347.63px;
            height: 553.42px;
          }
    }

}
.triangle-bg2 {
    position: absolute;
    bottom: -211px;
    z-index: 9;
    left: 0;
    right: 0;
    width: 100%;
  
}

.triangle-bg {
        bottom: 90px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        display: block;
     
 
}

.bee-image-mobile {
    display: none;
    @include breakpoint('sm_down') {
       display: block;
       position: absolute;
       width: 91.35px !important;
       height: 82.25px !important;
       margin: 0 !important;
       right:0;
       top: -90px;
      }

      @include breakpoint('xxs_down') {
        right: 66px;
    }

}
