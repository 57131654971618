.the-mission {
    &-wrapper {
        background-color: var(--color-blackish3);
        position: relative;
        height: 445.26px;
    }

    &-top {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        gap: 64.15px;
        padding-top: 76.62px;

        .header-text {
            min-height: 126px;
            color: #8DCF9F;
            @include breakpoint('xxs_down') {
                font-weight: 600;
                font-size: 28px;
                line-height: 35px;
            } 
        }

        .buttons-wrapped {
            display: flex;
            gap: 38.87px;

            .btn--white {
                color: white;
                border-color: white;
            }

        }
    }

}

.the-mission-items {
    min-height: 1481.7px;

    &-section {
        @include breakpoint('lg_down') {
            align-items: center;
           }
    }
    &-wrapper {
        background: linear-gradient(180deg, #1E2A41 0%, #293856 25.49%);
        position: relative;
        min-height: 1677.7px;
        margin-top: -1px;
        @include breakpoint('md_down') {
            min-height: 1470px;
        }
        @include breakpoint('sm_down') {
            min-height: 2300.7px;
        }
        @include breakpoint('xxs_down') {
            min-height: 1943px;
        }

        .next-section {
            background: #88CFDE;
            @include breakpoint('xxs_down') {
                height: 90px;
            }
        }

        .button-bar-btn {
            height: 250.9px;
            width: 100%;
            position: absolute;
            bottom: 90px;
            left: 0;
            right: 0;
            @include breakpoint('sm_down') {
                bottom: 105px;
            }
            @include breakpoint('xxs_down') {
                bottom: 90px;
            }
    
            .btn-inside-wrapper {
                position: relative;
                display: flex;
                align-items: baseline;
                img {
                    position: absolute;
                    right: 0;
                    left: 0;
                    width: 100%;
                    @include breakpoint('xlg_down') {
                        object-fit: cover;  
                        height: 250.9px;
                    }
                    @include breakpoint('xxs_down') {
                        min-height: 170px;
                        object-fit: cover;  
                    }
                }

                .btn {
                    position: absolute;
                    left: 50%;
                    width: 245px;
                    height: 56px;
                    transform: translateX(-50%);
                    top: 116.72px;
                    font-weight: 600;
                    line-height: 26px;
                }
            }


        }

        h1 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
            color: #8DCF9F;
            text-align: center;
            margin-bottom: 30.63px;
            margin-top: 102.5px;
            @include breakpoint('xxs_down') {
                font-weight: 600;
                font-size: 28px;
                line-height: 35px;
                margin-bottom: 18.89px;
                z-index: 9999;
                position: relative;
            } 
          
        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            margin-bottom: 61px;

            @include breakpoint('sm_down') {
                width: 472.33px;
                margin: auto;
                margin-bottom: 305px;
            }
            @include breakpoint('xs_down') {
                width: 370px;
              }
              @include breakpoint('xxs_down') {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                width: 320.94px;
                letter-spacing: 0.02em;
                z-index: 9999;
                position: relative;
                margin-bottom: 165px;
            } 
        }


        .all-items-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 500px);
            column-gap: 103.06px;
            row-gap: 101.73px;
            max-width: 1118.92px;
            margin: 0 auto;
            position: relative;
            z-index: 9;
            @include breakpoint('lg_down') {
               column-gap: 4rem;
               }

             @media  (max-width: 1100px) {
                column-gap: 8px;
               }
            @include breakpoint('md_down') {
                grid-template-columns: repeat(2, 50%);
                column-gap: 8px;
               }
               @include breakpoint('sm_down') {
                grid-template-columns: auto;
                row-gap: 70px;
            }
            @include breakpoint('xxs_down') {
                row-gap: 32px;
            }
            .box-image {
                position: relative;
                display: flex;
                justify-self: center;
                align-items: center;
                width: 100%;
                width: 459.01px;
                height: 459.01px;
                border-radius: 50%;
                cursor: pointer;
          
    
                @include breakpoint('md_down') {
                    width: 300.27px;
                    height: 300.27px;
                   }
                   
                   @include breakpoint('xxs_down') {
                    width: 300.27px;
                    height: 300.27px;
                   }
                .image-disc {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .box-img-inside {
                        z-index: 2;
                        position: relative;
                        @include breakpoint('md_down') {
                            max-width: 192px;
                        }
                    }


                    .discs-img {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                    }

                    &:hover {
                        .discs-img {
                            animation-name: rotate;
                            animation-duration: 3s;
                        }
                    }
                }

                &-bg {
                    background: rgba(22, 31, 49, 0.8);

                }

                .bx-title {
                    position: absolute;
                    left: 50%;
                    top: 150px;
                    transform: translateX(-50%);
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: center;
                    color: #5B76A2;
                    width: 150px;
                    @include breakpoint('md_down') {
                        transform: translate(-50%, -50%);
                        top: 110px;
                    }
                    @include breakpoint('xxs_down') {
                        font-weight: 600;
                        font-size: 17.8812px;
                        line-height: 25px;
                        margin-bottom: 53px;
                        top: 40%;
                        width: 130px;
                    }
                }

                .bx-desc {
                    position: absolute;
                    left: 50%;
                    top: 300px;
                    transform: translateX(-50%);
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 30px;
                    text-align: center;
                    color: #5B76A2;
                    @include breakpoint('md_down') {
                        transform: translate(-50%, -50%);
                        top: 200px;
                    }
                    @include breakpoint('xxs_down') {
                        font-weight: 400;
                        font-size: 13.1129px;
                        line-height: 18px;
                        top: 60%;
                    }
                }
            }
        }
    }

    .master-img {
        position: absolute;
        left: 20%;
        top: 22%;
        @include breakpoint('md_down') {
            left: 50%;
            top: 18%;
            transform: translateX(-50%);
            width: 500px;
           }

           @include breakpoint('sm_down') {
            top: -350px;
            width: unset;
           }

           @include breakpoint('xxs_down') {
            display: none;
           }
    }

    .master-img-mobile {
        display: none;
        @include breakpoint('xxs_down') {
            display: block;
            position: absolute;
            left: 50%;
            top: -250px;
            transform: translateX(-50%);
           }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(30deg);
    }
}